
import { defineComponent, PropType } from "vue";
import { useComputedFn, formatUtcDate } from "@/core/directive/function/common";
import { KycType, KycDisplayStatus } from "@/core/directive/type/kyc";
import { getKycDisplayStatus } from "@/core/directive/function/kyc";

import {
  BillingAddressInfo,
  billingAddressInfoData,
} from "@/core/directive/interface/kyc";

export default defineComponent({
  props: {
    type: {
      type: String as PropType<KycType>,
      default: KycType.Juridical,
    },
    data: {
      type: Object as PropType<BillingAddressInfo>,
      default: billingAddressInfoData,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    /**
     * @description: 渲染：状态
     */
    const getStatus = useComputedFn(getKycDisplayStatus);
    return {
      KycType,
      KycDisplayStatus,
      formatUtcDate,
      getStatus,
    };
  },
});
